<template>
  <div class="admin-guidance-external-links">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button type="default" style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/guidance')" icon="left"></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Guidance - External Links
      </h1>
      <div class="actions">
        <a-button @click.prevent="loadExternalLinks" :disabled="isLoading" class="button-margin-left btn-rounded"
          icon="reload" size="large" type="default">Refresh</a-button>

        <a-button v-if="showCreateButton" @click.prevent="createExternalLink" class="button-margin-left btn-rounded"
          icon="plus" size="large" type="primary">Add</a-button>
      </div>
    </div>
    <!-- / Page Header -->

    <!-- List wrapper -->
    <div class="external-links-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <div class="admin-guidance-context-selector-wrapper">
        <admin-guidance-context-selector v-if="!isLoading"></admin-guidance-context-selector>
      </div>

      <!-- No external links -->
      <a-alert v-if="!isLoading && externalLinksToShow.length == 0" type="info" message="No external links to show">
      </a-alert>
      <!-- / No downloads -->

      <!-- External links list -->
      <div class="external-links-list" :key="ownerIdFilter" v-if="!isLoading && externalLinksToShow.length">
        <a-row type="flex" :gutter="20">
          <a-col :span="colSpan" v-for="externalLink in externalLinksToShow" :key="externalLink.id">
            <external-link-list-item :external-link="externalLink" :tenant-id="tenantId"
              :org-id="selectedOrganisation.id" @selected="() => navigateToExternalLink(externalLink)">
            </external-link-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / External links list -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ExternalLinkListItem from './ExternalLinks/ExternalLinkListItem.vue';
import AdminGuidanceContextSelector from "../../../components/Admin/Guidance/AdminGuidanceContextSelector.vue";
export default {
  components: { ExternalLinkListItem, AdminGuidanceContextSelector },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadExternalLinks();
  },

  watch: {
    selectedOrganisation() {
      this.loadExternalLinks();
    },
  },

  methods: {
    ...mapActions("adminExternalLinks", {
      loadExternalLinks: "loadExternalLinks",
    }),

    navigateToExternalLink(externalLink) {
      this.$router.push("/admin/guidance/external-links/" + externalLink['$v'].id);
    },

    createExternalLink() {
      this.$router.push("/admin/guidance/external-links/create");
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminExternalLinks", {
      isLoading: "isLoading",
      externalLinksToShow: "externalLinksToShow",
    }),

    ...mapGetters("adminGuidance", {
      ownerIdFilter: 'ownerIdFilter'
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },

    showCreateButton() {
      return (!this.ownerIdFilter || this.ownerIdFilter == this.selectedOrganisation.id)
    }
  },
};
</script>

<style scoped lang="scss">
.external-link-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.admin-guidance-context-selector-wrapper {
  margin-bottom: 30px;
}
</style>